<script setup>
import AppLayout from "@/Layouts/AppLayout.vue";
import Container from "@/Components/Shared/Container.vue";
import TextInput from "@/Components/Shared/Forms/TextInput.vue";
import InputLabel from "@/Components/Shared/Forms/InputLabel.vue";
import Button from "@/Components/Shared/Forms/Button.vue";
import {onMounted, watch} from "vue";
import DeBestelAppHead from "@/Components/Shared/DeBestelAppHead.vue";
import {useCheckoutDetails} from "@/Composables/checkoutDetails";
import route from '@/Helpers/route';

const {
    zipcode, saveDetails, loadDetails
} = useCheckoutDetails();

watch(zipcode, () => saveDetails());

onMounted(() => loadDetails());
</script>

<template>
    <DeBestelAppHead title="Homepagina" />

    <AppLayout>
        <section class="mt-0.5 flex flex-row justify-start bg-gray-100 xl:min-h-[75vh] min-[1700px]:bg-[url('/images/misc/bg-1.svg')] min-[1700px]:bg-no-repeat min-[1700px]:bg-right-top min-[1700px]:bg-[length:auto_1200px]">
            <Container class="my-auto py-12">
                <div class="grid grid-cols-1 xl:grid-cols-5 gap-8">
                    <div class="md:col-span-3">
                        <h1 class="font-bold text-[52px] md:text-[64px] text-dba-red-400 leading-tight mb-2">Even wat eten bestellen</h1>
                        <p class="font-semibold text-[22px] md:text-[28px] text-gray-600 leading-tight mb-8">Wat valt er in de buurt te halen?</p>

                        <form method="get" :action="route('app.search')">
                            <div class="bg-white p-2 shadow rounded-xl mb-4">
                                <InputLabel for="search" value="Zoeken" class="sr-only" />
                                <TextInput id="search" type="text" class="block w-full" placeholder="Jouw postcode" dusk="search" name="zipcode" v-model="zipcode" required />
                            </div>

                            <Button variant="primary" size="lg">Zoeken</Button>
                        </form>
                    </div>
                </div>
            </Container>
        </section>
    </AppLayout>
</template>
